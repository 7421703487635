<template>
    <div class="">
    <b-container :class="[isMobile ? 'mt-5 pt-4 mb-5 pb-5' : 'mt-4' ]">
        <b-button variant="primary" class="mb-4" v-if="!isMobile" @click="goBack"><font-awesome-icon icon="fa-solid fa-chevron-left" /> Back</b-button> 
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group description="Recipent">
                    <b-input-group prepend="To:">
                        <b-form-input id="email" v-model="recip.primary" trim></b-form-input>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group description="Send CC email to?">
                    <b-input-group prepend="CC:">
                        <b-form-input id="cc" v-model="recip.cc" trim :disabled="!sendCC"></b-form-input>
                        <b-input-group-append is-text>
                            <b-form-checkbox switch class="mr-n2" v-model="sendCC" :value="true" :unchecked-value="false">
                                <span class="sr-only">Switch to send CC</span>
                            </b-form-checkbox>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" sm="4">
                <b-form-group description="Greeting">
                    <b-form-input id="greeting" v-model="greeting" trim></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="12" sm="8">
                <b-form-group description="Subject">
                    <b-form-input id="subject" v-model="subject" trim></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-form-group description="Message">
                    <b-form-textarea id="msg" v-model="msg" rows="5"></b-form-textarea>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="4" cols="6">
                Thanks,<br>
                <b-form-input id="signature" v-model="signature" trim></b-form-input>
                Twin Inc<br>
                732-240-3780 | www.twininc.com
                
            </b-col>
        </b-row>
         <b-row>
            <b-col sm="4" cols="6">
                <div class="d-flex mt-1">
                    <b-button variant="success" class="mr-1" @click="sendEmail">Send <font-awesome-icon icon="fa-regular fa-paper-plane"></font-awesome-icon></b-button>
                    <attachment-picker :contractId="contractInfo.contractId" @filesAttached="handleAttachedFiles"/>
                </div>
                <br>
                <b>Attachments:</b> <br>
                <div v-if="sendContract" class="mt-2"> 
                    <font-awesome-icon icon="fa-solid fa-file-contract fa-lg" /> {{$route.params.contractLink.file}}
                </div>
                <div v-if="sendInvoice" class="mt-2"> 
                    <font-awesome-icon icon="fa-solid fa-file-contract fa-lg" /> {{$route.params.invoiceLink.file}}
                </div>
                <div v-for="brochure in attachedFiles.brochures" class="mt-2" :key="brochure.name"> 
                    <font-awesome-icon icon="fa-regular fa-file-pdf fa-lg" /> {{brochure.name}}
                </div>
                <div v-for="file in attachedFiles.files" class="mt-2" :key="file.name"> 
                    <font-awesome-icon icon="fa-solid fa-file fa-lg" /> {{file.name}}
                </div>
            </b-col>
        </b-row>
        <!--
        <b-row class="mt-4">
            <b-col>
                <b-button variant="success" @click="sendEmail">Send <font-awesome-icon icon="fa-regular fa-paper-plane"></font-awesome-icon></b-button>
            </b-col>
        </b-row>
    -->
    </b-container>
    </div>
</template>

<script>
import attachmentPicker from '../components/attachmentPicker.vue'


export default {
  components: { attachmentPicker },
    name: 'email',
    computed: {
        userInfo () {
            return this.$state.userInfo
        },
        email () {
            return this.$route.params.email
        },
        cc () {
            return this.$route.params.cc
        },
        job () {
            return this.$route.params.job
        },
        customer () {
            return this.$route.params.customer
        },
        greeting () {
            return `${this.$route.params.fname},`
        },
        isMobile () {
          return this.$state.isMobile
        }
    },
    data() {
        return {
            msg: "",
            subject: "",
            recip: {
                primary: null,
                cc: null
            },
            signature: "",
            sendContract: false,
            sendInvoice: false,
            emailSent: false,
            contractInfo: {},
            attachedFiles: {},
            sendCC: false
        }
    },
    methods: {
        goBack() {
            this.$router.push({path: `/jobProfile/${this.job}`})
        },
        async sendEmail() {
            //links hust have https:// and be branded with twininc.com to get re-written
            
            let emailType = "dm"
            if(this.sendContract){
                emailType = "contract"
            }

            if(this.sendInvoice){
                emailType = "invoice"
            }

            let email = {
                to: this.recip.primary,
                from: this.userInfo.email,
                fromUser: this.userInfo._id,
                subject: this.subject,
                text: this.msg,
                customer: this.customer,
                job: this.job,
                emailType: emailType,
                greeting: this.greeting,
                signature: this.signature,
                links: []
            }
            if(this.recip.cc && this.sendCC){
                email.cc = this.recip.cc
            }

            if(Object.hasOwn(this.attachedFiles, 'brochures')){
                if(this.attachedFiles.brochures.length > 0) {
                    this.attachedFiles.brochures.forEach(brochure => {
                        email.links.push(brochure)
                    })
                }
            }
            

            if(Object.hasOwn(this.attachedFiles, 'files')) {
                if(this.attachedFiles.files.length > 0) {
                    this.attachedFiles.files.forEach(file => {
                        email.links.push(file)
                    })
                }    
            }

            if(this.sendContract){
                email.links.unshift({
                    extension: ".pdf",
                    name: this.contractInfo.file,
                    type: "file",
                    contract: true,
                    contractId: this.contractInfo.contractId
                })
            }

            if(this.sendInvoice){
                email.links.unshift({
                    extension: ".pdf",
                    name: this.contractInfo.file,
                    type: "file",
                    invoice: true,
                    contractId: this.contractInfo.contractId
                })
            }

            console.table(email)

            try {
                console.log(`Sending req...`)
                let res = await this.$axios.put(`/email`, email)

                console.log(res)
                if(res.status === 200) {
                    this.emailSent = true
                    //log it & return to prvious page
                    console.log(`Status is 200. Log it with emailId: ${res.data.emailId}`) 
                    let log = {
                        logTime: Math.floor(Date.now() / 1000),
                        logMsg: `Email sent to ${email.to}`,
                        job: email.job,
                        logUser: this.userInfo.name,
                        addInfo: `Message text: ${email.text}; Attachments: ${JSON.stringify(email.links)}.`,
                        recip: email.to,
                        email: res.data.emailId,
                        emailType: emailType
                    }
                    
                    this.$actions.log(log)
                    this.$root.$bvToast.toast(`Email successfully sent to ${email.to}`, {
                        title: "Message Sent",
                        variant: "primary",
                        autoHideDelay: 6000,
                    })
                    this.$router.push({path: `/jobProfile/${this.job}`})

                }
            } catch (err) {
                console.log(`woops there was and error: ${err}`)
                console.error(err)
            }
        },
        handleAttachedFiles(files) {
            files.brochures.forEach(file => {
                file.URIName = encodeURI(file.name)
            })
            this.attachedFiles = files
        }
        
    },
    created () {
        this.recip.primary = this.email
        this.recip.cc = this.cc
        this.signature = this.userInfo.name

        if(this.$route.params.contractLink) {
            this.sendContract = true
            this.msg = "Please click on the button below to view your proposal. Let me know if you have any questions."
            this.subject = "Twin Inc. Proposal"
            this.contractInfo = this.$route.params.contractLink
        }

        if(this.$route.params.invoiceLink) {
            this.sendInvoice = true
            this.msg = "Please click on the button below to view your invoice. Let me know if you have any questions."
            this.subject = "Twin Inc. Invoice"
            this.contractInfo = this.$route.params.invoiceLink
        }
    },
    async beforeDestroy(){
        //was file printed, emailed or downloaded? if not you can delete this file
        if(this.sendContract && this.emailSent) {
            console.log ("Contract was sent via email. Saving file.")
        } else {

            let payload = {
                contractId: this.contractInfo.contractId,
                filename: this.contractInfo.file
            }
            console.log("This contract was not sent via email. Delete file.")
            
            try {
                const res = await this.$axios.post(`/pdf/delete`, payload, {
                        headers: { Authorization: `Bearer ${this.$state.token}` 
                    }
                })
                console.log(res)

            } catch (err) {
                console.error(err)
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
    
</style>