<template>
    <div>
    <b-button id="show-btn" variant="outline-dark" @click="showAttachmentsModal">Add <font-awesome-icon icon="fa-solid fa-paperclip"></font-awesome-icon></b-button>

    <b-modal id="attachmentsModal" title="Pick Attachments" scrollable size="xl" @ok="addAttachments">
        <div class="d-flex flex-wrap brochure-div">
            <div v-for="brochure in brochures" :key="brochure.name" class="border brochure">
                <b-form-checkbox v-model="selectedAttachments.brochures" :value="brochure">
                    <font-awesome-icon v-if="brochure.extension == '.pdf'" icon="fa-regular fa-file-pdf" size="lg"></font-awesome-icon>
                    <font-awesome-icon v-else-if="brochure.extension == '.jpg'" icon="fa-regular fa-file-image" size="lg"></font-awesome-icon>
                    <font-awesome-icon v-else-if="brochure.extension == '.jpeg'" icon="fa-regular fa-file-image" size="lg"></font-awesome-icon>
                    <font-awesome-icon v-else-if="brochure.extension == '.png'" icon="fa-regular fa-file-image" size="lg"></font-awesome-icon>
                    <font-awesome-icon v-else icon="fa-regular fa-file" size="lg"></font-awesome-icon>
                    {{brochure.name}}

                </b-form-checkbox>
            </div>
        </div>
    </b-modal>
    
    </div>
</template>

<script>

export default {
  name: "attachmentPicker",
  props: {
      contractId: Number,
  },
  computed: {
      userInfo() {
          return this.$state.userInfo
      } 
  },
  data() {
    return {
        brochures: [],
        availableFiles: [],
        selectedAttachments: {
            brochures: [],
            files: []
        }
    }
  },
  created() {
      //this.getAvailableFiles()
      this.getBrochures()    
  },
  methods: {
    async getAvailableFiles() {

        /*
        try {
            let res = await this.$axios.get(`/user/`, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })

            //console.log("Users:")
            //console.log(res.data)
            let salesmanOptions = []
            let salesmanIds = []
            
            res.data.forEach(user => {
                if(user.status.toLowerCase() === "active" ) {
                    salesmanOptions.push(user.name)
                    salesmanIds.push({ name: user.name, _id: user._id})
                }       
            })
            
            this.salesmanOptions = salesmanOptions
            this.salesmanIds = salesmanIds

        } catch (err) {
            console.error(err)
        } 
        */
    },
    async getBrochures() {
        try {
            let res = await this.$axios.get(`/dirlist/brochures/list`, {
                headers: {
                    Authorization: `Bearer ${this.$state.token}`
                }
            })
            
            this.brochures = res.data

            
            
            console.log("Brochures Loaded")

        } catch (err) {
            console.error(err)
        } 
    },
    async addAttachments() {
        console.log("sending slectedAttachments array back to email.vue")
        this.$emit("filesAttached", this.selectedAttachments)
    },
    showAttachmentsModal() {
        this.$bvModal.show('attachmentsModal')
    }
    
  }
}

</script>


<style lang="scss" scoped>
    .brochure-div {
        box-sizing: border-box;
    }

    .brochure {
        height: 2.5em;
        padding: 5px 5px 5px 10px;
        line-height: 1.8em;
        width: 100%;
        margin: .25em;
        word-break: break-all;
        overflow: hidden;

        @media screen and (min-width: 992px) {
            width: calc(50% - .5em)
        }

        @media screen and (min-width: 1200px) {
            width: calc(33.3% - .75em)
        }
    }
</style>